const slider = tns({
	autoplay: false,
	container: '#slideshow .slick',
	controls: true,
	controlsContainer: '#slideshow .slick-controls',
	items: 1,
	lazyload: true,
	lazyloadSelector: '.tns-lazy',
	mouseDrag: true,
	slideBy: 'page',
	swipeAngle: false
})

const buyUrls = {
	default: 'https://pay.sketch.io/product/sketchpad/?add-to-cart=7986',
	macOS: 'https://apps.apple.com/app/id1562886727',
	windows: 'https://www.microsoft.com/en-us/p/sketchpad-premium/9p9821qbkv9x'
}

const agent = app.agent
const $downloadCta = document.querySelector('#download-cta')
const $buyButton = $downloadCta.querySelector('a')

if (agent.mac) {
	$buyButton.href = buyUrls['macOS']
} else if (agent.windows10) {
	$buyButton.href = buyUrls['windows']
} else {
	$buyButton.href = buyUrls['default']
}